<template>
  <div class="bg-light rounded-3 px-md-2 mb-3">
    <div class="steps pt-4 pb-1">
      <div v-for="(stage, i) in stages" :key="i" class="step" :class="{'active': isStageActive(i)}">
        <div class="step-progress">
          <span class="step-progress-start"></span
          ><span class="step-progress-end"></span
        ><a href="#" @click.prevent="attemptSetWizardStage(i)" class="step-number">{{ i + 1 }}</a>
        </div>
        <div class="step-label"><a @click.prevent="attemptSetWizardStage(i)" href="#">{{ stage }}</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentStage'],
  data() {
    return {
      stages: [
        'Client Details',
        'Lawyer Selection',
        'Review'
      ]
    }
  },
  methods: {
    isStageActive(i) {
      return i <= this.currentStage
    },

    setWizardStage(i) {
      this.$emit('set-stage', i);
    },

    attemptSetWizardStage(i) {
      if (i < this.currentStage) {
        this.setWizardStage(i);
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.step-number {
  text-decoration: none;
}

.step-label a {
  color: #666276;
  text-decoration: none;
}
</style>