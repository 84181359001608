<template>
  <div :class="{'fade-out': fadeOut}">
    <i class="h2 mb-1 fi-clock opacity-80"></i>
    <div class="text">
      {{ messageToShow }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NoLawyersAcceptedCarousel",
  data() {
    return {
      fadeOut: false,
      placeholder: "Waiting for the first lawyer to start a conversation with you...",
      messages: [
        "We've notified our recommended lawyers about your request",
        "Your lawyers will discuss your needs via Syndesi and provide you with a quote",
        "You choose the quote you prefer to instruct your lawyer",
        "We'll email you when the first lawyer starts a conversation with you"
      ],
      messageIndex: null
    }
  },
  created() {
    let vm = this;
    setInterval(function () {
      vm.fadeOut = true;
      setTimeout(function() {
        if (vm.messageIndex != null) {
          vm.messageIndex += 1;
        } else {
          vm.messageIndex = 0;
        }
        vm.fadeOut = false;
      }, 500);
    }, 7000);
  },
  computed: {
    messageToShow() {
      if (this.messageIndex !== null) {
        return this.messages[this.messageIndex % this.messages.length];
      }
      return this.placeholder;
    }
  }
}
</script>

<style scoped>
.text {
  transition: color 0.5s; /* text color transition duration = 500ms */
}

.fade-out {
  color: transparent;
}
</style>