<template>
  <div class="who-am-i-talking-to">
    <div>
      <conversation-members-button-and-modal class="me-2" :conversation="conversation"></conversation-members-button-and-modal>
      You are talking to <span>{{ rolesFormatted }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ConversationMembersButtonAndModal from "./WhoAmITalkingTo/ConversationMembersButtonAndModal";

export default {
  name: "WhoAmITalkingTo",
  components: {ConversationMembersButtonAndModal},
  props: ['job', 'conversation'],
  methods: {},
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      // isClient: 'isClient',
      // isAdvisor: 'isAdvisor',
      // isProvider: 'isProvider'
    }),

    rolesOfConversationMembers() {
      let roles = [];
      _.each(this.conversation.conversation_members, member => {
        if (member.user_role && member.user_role_id !== this.userRole.id) {
          roles.push({
            role: member.user_role.role,
            name: member.user_role.name,
            company_name: (member.user_role.company ? member.user_role.company.name : null)
          });
        }
      });
      return roles;
    },

    rolesFormatted() {
      return _.map(this.rolesOfConversationMembers, role => {
        let entry = role.name;
        if (role.company_name) {
          entry = entry + ' (' ;

          if (role.role == 'advisor') {
            entry = entry + 'Accountant at '
          }
          if (role.role == 'provider') {
            entry = entry + 'Lawyer at '
          }

          if (role.role == 'client') {
            // entry = entry + 'Client - ';
            entry = entry + 'Client';
          }

          if (
              role.name !== role.company_name
              && role.role !== 'client'
          ) {
            entry = entry + role.company_name ;
          }

          entry = entry + ')';
        }
        return entry;
      }).join(', ');
    },
  }
}
</script>

<style scoped lang="scss">
.who-am-i-talking-to {
  flex-shrink: 1;
  //background: #fff;
  padding: 0 15px;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  //justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #efecf3 !important;
  font-size: 13.5px;

  color: #1f1b2d;
  font-weight: 500;

  background: rgba(224, 224, 224, 0.6);

  span {
    font-weight: 600;
  }
}
</style>